import React, { useEffect, useState } from "react";

import {
    Row,
    Col,
    Input,
    Button,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
} from "reactstrap";

import Select from 'react-select';
import fileDownload from 'js-file-download';
import axios from "axios";
import externalUrl from "config/externalUrl";
import { Redirect } from "react-router-dom";

const options = [
    { label: "Créditos vigentes", value: 1, },
    { label: "Crédito específico", value: 2, },
    { label: "Cédula específica", value: 3, },
    /* { label: "Rango de créditos", value: 4, }, */
    { label: "Todos los créditos", value: 4, },
];

function ModalLedger(props) {
    const [userData] = useState(JSON.parse(localStorage.getItem('userData')));
    const [loading, setLoading] = useState(false);

    const [formType, setFormType] = useState(1);
    const [loan, setLoan] = useState(0);
    const [cedula, setCedula] = useState(0);
    const [position, setPosition] = useState('');
    const [name, setName] = useState('');
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);

    const [loans, setLoans] = useState([]);
    const [cedulas, setCedulas] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        axios.get(`${externalUrl.reportsUrl}/v1/loan/index`, {
            headers: { Authorization: `Bearer ${userData.private_token}`, },
        }).then(function (response) {
            if (response.data.error) {
                setLoans([]);
            } else {
                const data = response.data.msg.map((loan => ({ value: loan.loan_id_exp, label: loan.loan_id_exp + ' - ' + loan.name, id: loan.loan_id })));
                setLoans(data);
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem('userData');
                window.location.replace(`${externalUrl.reportsUrl}/v1/auth/logout`);
            } else {
                console.error(error);
            }
        });
    };


    async function getCedulas(e) {
        setLoan(e.value);
        axios.get(`${externalUrl.reportsUrl}/v1/loan/search?id=${e.id}`, {
            headers: { Authorization: `Bearer ${userData.private_token}`, },
        }).then(function (response) {
            if (response.data.error) {
                setLoans([]);
                setCedula(0);
            } else {
                const data = response.data.cedulas.map((cedula => ({ value: cedula.id, label: "Cédula # " + cedula.position, id: cedula.position })));
                setCedulas(data);
                setCedula(0);
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem('userData');
                window.location.replace(`${externalUrl.reportsUrl}/v1/auth/logout`);
            } else {
                console.error(error);
            }
        });
    };

    const download = () => {
        var typeUrl = '';
        var nameFile = '';
        if (formType == 1) {
            typeUrl = 'nomina-vigentes';
            nameFile = 'NominaVigentes';
        } else if (formType == 2) {
            typeUrl = 'nomina-specific-loan';
            nameFile = `NominaCredito_${loan}`;
        } else if (formType == 3) {
            typeUrl = 'nomina-specific-cedula';
            nameFile = `NominaCedula_${loan}_${position}`;
        }/*  else if (formType == 4) {
            typeUrl = 'nomina-range';
            nameFile = 'RangoNomina';
        } */ else if (formType == 4) {
            typeUrl = 'nomina-history';
            nameFile = 'NominaHistorico';
        }

        let body = {
            val: formType === 2 ? loan : formType === 3 ? cedula : 0,
            min: min,
            max: max
        };
        axios.post(`${externalUrl.reportsUrl}/v1/external/${typeUrl}`,
            body,
            {
                responseType: 'blob',
                headers: { Authorization: `Bearer ${userData.private_token}`, },
            }).then(function (response) {
                if (response.data.error) {

                }
                else {
                    fileDownload(response.data, `${nameFile}.xlsx`);
                }
            }).catch(function () {
                setLoading(false);
            });
    };

    const toggle = () => {
        props.setShow(!props.show);
    }

    const changeValueSelect = (e, setter, obj) => {
        setter(e.value);
    }

    const changeCedula = (e, setter, obj) => {
        setter(e.value);
        obj(e.id);
    }

    return (
        <>
            <Modal isOpen={props.show}
                toggle={toggle}
                style={{ top: '5vh' }}>
                <ModalHeader style={{ justifyContent: 'center !important' }}>
                    Nóminas de cédulas
                </ModalHeader>
                <ModalBody style={{ paddingBottom: '0.3rem' }}>
                    <Label>
                        Seleccione el tipo de reporte que desea descargar:
                    </Label>
                    <Row style={{ marginBottom: '1rem' }}>
                        <Col md='3' />
                        <Col md='6'>
                            <FormGroup style={{ width: '100%' }}>
                                <Select
                                    options={options}
                                    placeholder={options[formType - 1].label}
                                    noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
                                    name='options'
                                    onChange={(e) => {
                                        changeValueSelect(e, setFormType, formType);
                                    }}
                                    className="create-select"
                                />
                            </FormGroup>
                        </Col>
                        <Col md='3' />
                    </Row>
                    <>
                        {
                            formType === 2 ?
                                <>
                                    <hr />
                                    <FormGroup style={{ width: '100%' }}>
                                        <label>Crédito</label>
                                        <Select
                                            options={loans}
                                            placeholder={loan === 0 ? "Seleccione..." :
                                                loans.find(val => val.value == loan)?.label}
                                            noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
                                            name='options'
                                            onChange={(e) => { changeValueSelect(e, setLoan, loan); getCedulas(e); }}
                                            className="create-select"
                                        />
                                    </FormGroup>
                                </>
                                :
                                formType === 3 ?
                                    <>
                                        <hr />
                                        <FormGroup style={{ width: '100%' }}>
                                            <label>Crédito</label>
                                            <Select
                                                options={loans}
                                                placeholder={loan === 0 ? "Seleccione..." :
                                                    loans.find(val => val.value == loan)?.label}
                                                noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
                                                name='options'
                                                onChange={(e) => { getCedulas(e); }}
                                                className="create-select"
                                            />
                                        </FormGroup>
                                        <FormGroup style={{ width: '100%' }}>
                                            <label>Cedula</label>
                                            <Select
                                                options={cedulas}
                                                placeholder={"Seleccione..."}
                                                noOptionsMessage={({ inputValue }) => !inputValue ? "Cargando..." : "Sin resultados"}
                                                name='options'
                                                onChange={(e) => { changeCedula(e, setCedula, setPosition); }}
                                                className="create-select"
                                                value={cedula === 0 ? { value: 0, label: 'Seleccione...' } : cedulas.find(val => val.id === cedula)?.label}
                                            />
                                        </FormGroup>
                                    </>
                                    :
                                    /* formType === 4 ?
                                        <hr />
                                        : */
                                        <></>
                        }
                    </>
                </ModalBody>
                <ModalFooter style={{ paddingTop: '0.3rem', paddingBottom: '0.3rem' }}>
                    <Button
                        className={"btn button-alapar-success button-left"}
                        onClick={download}
                    >
                        Descargar
                    </Button>
                    {' '}
                    <Button
                        className="button-alapar-cancel button-right"
                        outline
                        color="primary"
                        onClick={toggle}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default ModalLedger;
