import React from "react";

import {
    Button,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";



function ModalMetodology(props) {
    const toggle = () => {
        props.setShow(!props.show);
    }

    return (
        <>
            <Modal isOpen={props.show}
                toggle={toggle}
                style={{ top: '5vh' }}>
                <ModalHeader style={{ justifyContent: 'center !important' }}>
                    {props.title}
                </ModalHeader>
                <ModalBody style={{ paddingBottom: '0.3rem' }}>
                    <Label>
                        {props.desc}
                    </Label>
                </ModalBody>
                <ModalFooter style={{ paddingTop: '0.3rem', paddingBottom: '0.3rem' }}>
                    <Button
                        className="button-alapar-cancel button-right"
                        outline
                        color="primary"
                        onClick={toggle}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default ModalMetodology;
