/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import styles from "../assets/jss/institutionScreenstyle.js";
import ModalLedger from "components/Modals/Ledger";
import { makeStyles } from "@material-ui/core/styles";
// react plugin used to create charts
import { Link } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
} from "reactstrap";
import GraphCard from "../components/Graph/GraphCard.js";
import CardDownloadOption from "components/Card/DownloadOption.js";

const useStyles = makeStyles(styles);

const data = [
  {
    "name": "Inventario",
    "data": "9725",
    "year": "2014"
  },
  {
    "name": "Inventario",
    "data": "13724",
    "year": "2015"
  },
  {
    "name": "Inventario",
    "data": "13509",
    "year": "2016"
  },
  {
    "name": "Inventario",
    "data": "26734",
    "year": "2017"
  },
  {
    "name": "Inventario",
    "data": "24111",
    "year": "2018"
  },
  {
    "name": "Inventario",
    "data": "24199",
    "year": "2019"
  },
  {
    "name": "Inventario",
    "data": "22699",
    "year": "2020"
  },
  {
    "name": "Inventario",
    "data": "12469",
    "year": "2021"
  }
];

const data2 = [
  {
      "name": "Entradas",
      "data": "37885",
      "year": "2014"
  },
  {
      "name": "Salidas",
      "data": "28160",
      "year": "2014"
  },
  {
      "name": "Entradas",
      "data": "46534",
      "year": "2015"
  },
  {
      "name": "Salidas",
      "data": "32810",
      "year": "2015"
  },
  {
      "name": "Entradas",
      "data": "50209",
      "year": "2016"
  },
  {
      "name": "Salidas",
      "data": "36700",
      "year": "2016"
  },
  {
      "name": "Entradas",
      "data": "65307",
      "year": "2017"
  },
  {
      "name": "Salidas",
      "data": "38573",
      "year": "2017"
  },
  {
      "name": "Entradas",
      "data": "67844",
      "year": "2018"
  },
  {
      "name": "Salidas",
      "data": "43733",
      "year": "2018"
  },
  {
      "name": "Entradas",
      "data": "69378",
      "year": "2019"
  },
  {
      "name": "Salidas",
      "data": "45179",
      "year": "2019"
  },
  {
      "name": "Entradas",
      "data": "51065",
      "year": "2020"
  },
  {
      "name": "Salidas",
      "data": "28366",
      "year": "2020"
  },
  {
      "name": "Entradas",
      "data": "58024",
      "year": "2021"
  },
  {
      "name": "Salidas",
      "data": "45555",
      "year": "2021"
  }
];

function Dashboard() {
  const classes = useStyles();
  const [showLedger, setShowLedger] = React.useState(false);
  const [userData] = useState(JSON.parse(localStorage.getItem('userData')));

  console.log(userData.permissions)
  return (
    <>
      <div className="content">
        <Grid container>
          <Grid container xs={12} sm={12} md={12} spacing={1}>
            <Grid item xs={12} sm={12} md={4} key={1}>
              <GraphCard graphData={{ key: 1, title: 'Entradas y Salidas', data: data, metodology: 'Este es un mensaje de prueba' }} justDetailButton institution={{ name: 'Primer gráfico' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} key={1}>
              <GraphCard graphData={{ key: 2, title: 'Inventario Anual', data: data2, metodology: 'Este es un mensaje de prueba' }} justDetailButton institution={{ name: 'Primer gráfico' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} key={1}>
              <CardDownloadOption function={() => setShowLedger(true)} faIcon={'fas fa-file-excel fa-2x'} displayableName={'nominas de cédulas'} desc={'Descarga las nóminas de cédulas en formato MSOffice Excel'}/>
            </Grid>
          </Grid>
        </Grid>

        {/* <Row>
          <Col md="6">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Créditos</CardTitle>
              </CardHeader>
              <CardBody>
                <Link
                  to={{
                    pathname: "/admin/create-bank",
                  }}>
                  Préstamos desembolsados
                </Link>
                <br />
                <Link
                  to={{
                    pathname: "/admin/create-cedula",
                  }}>
                  Cédulas
                </Link>
                <br />
                <Link
                  to={{
                    pathname: "/admin/create-cedula-ownership",
                  }}>
                  Cédulas en propiedad
                </Link>
              </CardBody>
            </Card>
          </Col>
        </Row> */}
      </div>

      <ModalLedger setShow={setShowLedger} show={showLedger} />
    </>
  );
}

export default Dashboard;
