import { useState, useEffect } from "react";

// react-router components
import { Route, Switch, Redirect } from "react-router-dom";

import axios from 'axios';
import externalUrl from './config/externalUrl';
import './style/App.css';

import AdminLayout from "layouts/Admin.js";


export default function App() {
  const [user, setUser] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      var t = '';
      if (localStorage.getItem("userData") !== null) {
        try {
          t = window.location.search.split('?')[1].split('=')[1];
        } catch (e) { }
      }

      const token = localStorage.getItem("userData") === null ? { private_token: window.location.search.split('?')[1].split('=')[1] } : (t === '' ? JSON.parse(localStorage.getItem('userData')) : { private_token: t });
      axios.get(`${externalUrl.reportsUrl}/v1/api/session`, {
        headers: { Authorization: `Bearer ${token.private_token}`, },
      }).then(function (response) {
        if (!response.data.error) {
          const session_data = JSON.stringify(response.data.session_data);
          localStorage.setItem("userData", session_data);
          setUser({ ...response.data.session_data });
          try {
            if (window.location.search.split('?')[1].split('=')[0] === 'token') {
              window.history.replaceState(null, "ALAPAR-reports", "/")
              //window.location.assign("/");
            }
          } catch (e) { }
        } else {
          /* localStorage.removeItem('userData');
          if (response.data.hasOwnProperty('type')) {
            if (response.data.type === 1) {
              window.location.replace(`${externalUrl.identityUrl}`);
              return;
            }
          }
          window.location.replace(`${externalUrl.reportsUrl}/v1/auth/logout`); */
        }
      }).catch(function () {
        /* localStorage.removeItem('userData');
        window.location.replace(`${externalUrl.reportsUrl}/v1/auth/logout`); */
      });
    } catch (e) {
      /* localStorage.removeItem('userData');
      window.location.replace(`${externalUrl.reportsUrl}/v1/auth/logout`); */
    }
  }

  if (Object.keys(user).length === 0) {
    return (<></>);
  }

  return (
    <Switch>
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Redirect to="/admin/dashboard" />
    </Switch>
  );
}
