import React from "react";
import Grid from '@material-ui/core/Grid';
import { Typography } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";

import ModalMetodology from "components/Modals/Metodology";
import D3LinearChart from "components/Graph/D3LinearChart";
import { CardTitle } from "reactstrap";
import CardDownloadOption from "components/Card/DownloadOption";

const data = [
    {
        "name": "Inventario",
        "data": "9725",
        "year": "2014"
    },
    {
        "name": "Inventario",
        "data": "13724",
        "year": "2015"
    },
    {
        "name": "Inventario",
        "data": "13509",
        "year": "2016"
    },
    {
        "name": "Inventario",
        "data": "26734",
        "year": "2017"
    },
    {
        "name": "Inventario",
        "data": "24111",
        "year": "2018"
    },
    {
        "name": "Inventario",
        "data": "24199",
        "year": "2019"
    },
    {
        "name": "Inventario",
        "data": "22699",
        "year": "2020"
    },
    {
        "name": "Inventario",
        "data": "12469",
        "year": "2021"
    }
];

const data2 = [
    {
        "name": "Entradas",
        "data": "37885",
        "year": "2014"
    },
    {
        "name": "Salidas",
        "data": "28160",
        "year": "2014"
    },
    {
        "name": "Entradas",
        "data": "46534",
        "year": "2015"
    },
    {
        "name": "Salidas",
        "data": "32810",
        "year": "2015"
    },
    {
        "name": "Entradas",
        "data": "50209",
        "year": "2016"
    },
    {
        "name": "Salidas",
        "data": "36700",
        "year": "2016"
    },
    {
        "name": "Entradas",
        "data": "65307",
        "year": "2017"
    },
    {
        "name": "Salidas",
        "data": "38573",
        "year": "2017"
    },
    {
        "name": "Entradas",
        "data": "67844",
        "year": "2018"
    },
    {
        "name": "Salidas",
        "data": "43733",
        "year": "2018"
    },
    {
        "name": "Entradas",
        "data": "69378",
        "year": "2019"
    },
    {
        "name": "Salidas",
        "data": "45179",
        "year": "2019"
    },
    {
        "name": "Entradas",
        "data": "51065",
        "year": "2020"
    },
    {
        "name": "Salidas",
        "data": "28366",
        "year": "2020"
    },
    {
        "name": "Entradas",
        "data": "58024",
        "year": "2021"
    },
    {
        "name": "Salidas",
        "data": "45555",
        "year": "2021"
    }
];

export default function ChartDetailScreen() {
    let { id } = useParams();
    const [modalState, setModalState] = React.useState(false);
    const downloadOptions = [{ objAttribute: 'download_excel', type: 'excel', format: 'xlsx', desc: 'MSOffice Excel', faIcon: 'fas fa-file-excel fa-2x', displayableName: 'Excel' }, { objAttribute: 'download_csv', type: 'csv', format: 'csv', desc: 'CSV', faIcon: 'fas fa-file-csv fa-2x', displayableName: 'CSV' }, { objAttribute: 'download_xml', type: 'xml', format: 'xml', faIcon: 'fas fa-file-code fa-2x', desc: 'XML (de etiquetas)', displayableName: 'XML' }];

    const graphData = [{ key: 1, title: 'Entradas y Salidas', data: data, metodology: 'Este es un mensaje de prueba' }, { key: 2, title: 'Inventario Anual', data: data2, metodology: 'Este es un mensaje de prueba' }];
    return (
        <div className="content">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} className='divisoryClass'>
                    <div>
                        <Typography variant="subtitle2" style={{ textTransform: 'none', color: 'rgb(129, 135, 140)' }}>
                            <div dangerouslySetInnerHTML={{ __html: 'Es la diferencia entre las entradas y salidas de cada año analizado.' }} />
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <div className="graphOptionsMenuContainer" style={{ borderBottom: '1px solid #e4e9ec', paddingBottom: '0.2rem' }}>
                        <div className="graphOptionsMenuContainerTitle">
                            <Typography variant="h6" className="emphasizedHeader">{graphData[id - 1].title}</Typography>
                        </div>

                        <div className="graphOptionsMenuButton" onClick={() => setModalState(true)} style={{ marginTop: '0.2rem', marginRight: '0.2rem' }}>
                            <div className="graphOptionsMenuIcon">
                                <i class="fas fa-info fa-1x"></i>
                            </div>
                            <CardTitle variant="subtitle2">Metodología</CardTitle>
                        </div>
                    </div>
                    <div className='graphContainer' style={{ width: '100%', backgroundColor: 'white', display: 'flex', justifyContent: 'center' }}>
                        <div className='graphConstraint' style={{ width: '90%' }}>
                            <D3LinearChart width={450} height={300} source={graphData[id - 1].data} requiresLegends />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} className='rightContentContainer'>
                    <div className='dataOptions' style={{ border: '0px' }}>
                        {
                            downloadOptions.map((download, index) => (
                                <div key={index}>
                                    <CardDownloadOption function={() => (console.log('test'))} faIcon={download.faIcon} displayableName={download.displayableName} desc={`Descarga la información formato ${download.desc}`} />
                                    <hr style={{ margin: 0 }} />
                                </div>
                            ))
                        }

                    </div>
                </Grid>
            </Grid>

            <ModalMetodology title={graphData[id - 1].title} desc={graphData[id - 1].metodology} setShow={setModalState} show={modalState} />
        </div>
    );
}