const url = window.location.host;
var externalUrl = {};

if (url === 'dev.reports.alapar.local' || url === 'localhost:3000') {
    externalUrl.identityUrl = 'https://dev.login.alapar.local';
    externalUrl.lenderUrl = 'https://dev.app.lender.alapar.local';
    externalUrl.platformUrl = 'https://dev.platform.alapar.local';
    externalUrl.reportsUrl = 'https://dev.api.reports.alapar.local';
} else if (url === 'reports.qa.alapar.com.gt') {
    externalUrl.identityUrl = 'https://login.qa.alapar.com.gt';
    externalUrl.lenderUrl = 'https://app.lender.qa.alapar.com.gt';
    externalUrl.platformUrl = 'https://platform.qa.alapar.com.gt';
    externalUrl.reportsUrl = 'https://api.reports.qa.alapar.com.gt';
} else if (url === 'reports.alapar.com.gt') {
    externalUrl.identityUrl = 'https://login.alapar.com.gt';
    externalUrl.lenderUrl = 'https://app.lender.alapar.com.gt';
    externalUrl.platformUrl = 'https://platform.alapar.com.gt';
    externalUrl.reportsUrl = 'https://api.reports.alapar.com.gt';
} else {
    externalUrl.identityUrl = 'https://dev.login.alapar.local';
    externalUrl.lenderUrl = 'https://dev.app.lender.alapar.local';
    externalUrl.platformUrl = 'https://dev.platform.alapar.local';
    externalUrl.reportsUrl = 'https://dev.api.reports.alapar.local';
}


module.exports = externalUrl;
