import React from "react";
import { Typography } from "@material-ui/core";
import { Button } from "reactstrap";
import { FaFileCsv } from "react-icons/fa";

export default function CardDownloadOption(props) {
    //const downloadOptions = [{ objAttribute: 'download_excel', type: 'excel', format: 'xlsx', desc: 'MSOffice Excel', faIcon: 'fas fa-file-excel fa-2x', displayableName: 'Excel' }, { objAttribute: 'download_csv', type: 'csv', format: 'csv', desc: 'CSV', faIcon: 'fas fa-file-csv fa-2x', displayableName: 'CSV' }, { objAttribute: 'download_xml', type: 'xml', format: 'xml', faIcon: 'fas fa-file-code fa-2x', desc: 'XML (de etiquetas)', displayableName: 'XML' }];

    return (
        <Button className='buttonActionStyle' onClick={props.function}>
            <div className='dataOption'>
                <div className='dataOptionIconContainer'>
                    {
                        props.faIcon === 'fas fa-file-csv fa-2x' ?
                            <FaFileCsv style={{fontSize: '2em'}}/> :
                            <i class={props.faIcon}></i>
                    }

                </div>
                <div className='dataOptionTextHolder'>
                    <Typography variant="subtitle1" style={{ textTransform: 'none' }}>{"Descargar " + props.displayableName}</Typography>
                    <div className='dataOptionTextHolderExtra'>
                        <Typography variant="caption" style={{ textTransform: 'none' }}> {props.desc}  </Typography>
                    </div>
                </div>
            </div>
        </Button>
    )
};