const primaryScreenstyle = (theme) => ({
    title: {
        textAlign: 'left',
        fontSize: '2.3rem',// now its beging inherited from customTheme on index.js
        marginBottom:'0',
        paddingLeft: "10px"
        // [theme.breakpoints.up(768)]: { //now its beging inherited from customTheme on index.js
        //     fontSize: '3rem !important'
        // },
    },
    subtitleContainer:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'left',
        paddingLeft: "10px"
    },
    subtitle: {
        marginTop:'5px',
        textAlign: 'left',
        color: '#81878C',
        fontWeight:'normal',
        maxWidth:'450px'
    },
    longDescription:{
        width:"100%"
    },
    rightSideTextContainer:{
        marginTop:'10px',
        height:"218px" //to make it match with cards default margin 
    }
})

export default primaryScreenstyle;